body, html {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fefefe;
}

code {
  font-family: 'Courier New', monospace;
}


.contact-box-bottom {
  position: fixed;
  bottom: 54px;
  right: 10px;
  z-index: 10000
}

.contact-box-wrapper {
  display: flex;
  align-items: center;
  background: #fff;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .08)
}

.contact-icon-box {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 38px;
  border: 1px solid #e5e5e5;
  border-radius: 999px
}

.contact-info {
  padding-left: 10px;
}

.contact-info span {
  color: #868686;
  font-size: 12px;
  display: block;
}

.burple-colors {
  --accentColor: #635bff;
  --guideBackground: #5d69e3;
  --guideDarkColor: #4d5ae0;
  --guideLightColor: #6772e5;
  --guideLighterColor: #7a84e9;
  --guideLightestColor: #8d95ec;
}