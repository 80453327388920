

/*Print Dialog*/
@media print {
    
    @page {
       size: portrait;
       margin: 0;
       padding: 0;
    }
    
    body * {
        visibility: hidden;
    }

    html, body {
        background: none;
        -ms-zoom: 1.665;
        overflow: hidden;     
    }

    #A4Portrait,
    #A4Portrait * {
        visibility: visible;
    }
    #A4Landscape,
    #A4Landscape * {
        visibility: visible;
    }
    #A4Portrait, #A4Landscape {
        padding: 10mm;
        border: initial;
        border-radius: initial;
        width: initial;
        height: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
    }

    #A4Landscape {
        transform: rotate(270deg) translate(-276mm, 0);
        transform-origin: 0 0;
    }

    #A4Portrait {
        margin: 'auto';
        margin-top: 10mm;
        width: 190mm;
        height: 276mm;
    }
    #A4Landscape {
        margin: 'auto';
        margin-left: 20mm;
        width: 276mm;
        height: 190mm;
    }
      
  }